import { PAGE_START } from ".";
import useWebSocket from 'react-use-websocket';
import { WS_URL } from "../const";
import { useEffect } from "react";
import Gamers from "../components/gamers";

export default function PageLeaders({ setPage, commands }) {
    const { sendJsonMessage } = useWebSocket(WS_URL, {
        filter: () => false,
        share: true,
        retryOnError: true,
        shouldReconnect: () => true,
        reconnectInterval: 1000,
    });

    useEffect(() => {
        sendJsonMessage({
            action: 'getLeaders',
            data: {}
        })
    }, [sendJsonMessage])

    return (
        <>
            <div className="pageTitle">Лидеры месяца</div>
            <div className="middleBox">
                <Gamers gamers={commands} whiteBg={true} colorNumber={true} showScope={true} />
            </div>
            <div className="actions">
                <button className="green" onClick={() => setPage(PAGE_START)}>Назад</button>
            </div>
        </>
    );

}