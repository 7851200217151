
import { PAGE_START } from ".";
import instruction from '../images/instruction.svg';

export default function PageInstruction({ setPage }) {
    return (
        <>
            <div className="pageTitle">Время поднять<br />градус вечера</div>
            <div className="pageMiddleTitle">И сыграть в пикантный квиз</div>
            <img className="prize" src={instruction} width="274" height="436" alt="" />
            <div className="actions">
                <button className="green" onClick={() => setPage(PAGE_START)}>Все понятно</button>
            </div>
        </>
    );
}