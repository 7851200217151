// 1.	Экран подтверждение возраста 18+:
export const PAGE_18 = '18+'
// 1.5.	Инструкция:
export const PAGE_INSTRUCTION = 'instruction'
// 2.	Стартовый экран
export const PAGE_START = 'start'
// 3.	Создание игры
export const PAGE_CREATE = 'create'
// 4.	Присоединение к игре
export const PAGE_CONNECT = 'connect'
// 5.	Ожидания начала игры
export const PAGE_WAIT = 'wait'
// 6.	Процесс игры (экран с таймером, вопросом и ответами)
export const PAGE_GAME = 'game'
// 7.	Раунд завершен (2 варианта, промежуточный и итоговый)
export const PAGE_GAME_OVER = 'gameOver'
// 8.	Участвовать в ежемесячном розыгрыше (регистрация)
export const PAGE_REGISTRATION = 'registration'
export const PAGE_REGISTRATION_SUCCESS = 'registrationSuccess'
// 9.	Лидеры месяца  
export const PAGE_LEADERS = 'leaders'