import { useEffect, useState } from "react";
import useLocalStorageState from "use-local-storage-state";
import useWebSocket from 'react-use-websocket';
import { PAGE_CREATE, PAGE_REGISTRATION, PAGE_START } from ".";
import { WS_URL } from "../const";
import Timer from "../components/timer";
import Gamers from '../components/gamers';

export default function PageGameOver({ setPage, game, gamers }) {
    const [userId] = useLocalStorageState('userId')
    const [timeToNewRound, setTimeToNewRound] = useState(0)

    const { sendJsonMessage } = useWebSocket(WS_URL, {
        filter: () => false,
        share: true,
        retryOnError: true,
        shouldReconnect: () => true,
        reconnectInterval: 1000,
    });

    const startGame = () => {
        sendJsonMessage({
            action: 'start',
            data: {
                userId: userId,
            }
        })
    }

    useEffect(() => {
        if (game?.state === 2) {//раунд завершен
            const roundEnd = new Date(game.dateRoundEnd)
            const today = new Date()
            const timerSeconds = 10 - Math.round((today - roundEnd) / 1000)
            setTimeToNewRound(timerSeconds > 0 ? timerSeconds : 0)
        }
    }, [game]);

    const getTitle = () => {
        switch (game?.state) {
            case 0: return "Ожидание старта игры"
            case 1: return "Ожидание игроков"
            case 2: return "Раунд завершен"
            case 3: return "Игра завершена"
            default: return "Что то пошло не так"
        }
    }

    const handleExit = () => {
        if(window.confirm("Вы уверены что хотите завершить игру? Присоединится заново к уже начатой игре будет невозможно.")){
            sendJsonMessage({
                action: 'exit',
                data: {
                    userId: userId,
                }
            })
        }
    }

    gamers?.sort((a, b) => b.scope - a.scope)

    return (
        <>
            {game?.state === 2 && <Timer timeSecond={timeToNewRound} />}
            <div className="pageTitle">{getTitle()}</div>
            {game?.state === 2 && <div className="pageSubTitle">{(game?.round || 2) - 1} из 3 раундов. Ожидаем следующий раунд.</div>}
            <Gamers gamers={gamers} colorNumber={true} showScope={true}/>
            {game?.state !== 3 &&
                <div className="actions">
                    <button className="border red" onClick={handleExit}>Выйти из игры</button>
                </div>
            }
            {game?.state === 3 &&
                <>
                    <div className="gameResultText">
                        Победителем самого пикантного<br />квиза стал:<br />
                        <span>«{gamers[0].name}»</span>
                    </div>
                    <div className="gameResultText">
                        За сидром идет:<br />
                        <span>«{gamers[gamers.length - 1].name}»</span>
                    </div>
                    <div className="pageSubTitle">
                        “И помните, истина в вине.<br />А в сидре ваше непотерянное следующее утро!”<br />Сидоров закон
                    </div>


                    {game?.capitanId === userId &&
                        <div className="actions">
                            <button className="green" onClick={startGame}>Сыграть еще</button>
                            <button className="orange" onClick={() => setPage(PAGE_CREATE)}>Пригласить новых игроков</button>
                            <button className="red border" onClick={() => setPage(PAGE_REGISTRATION)}>Участвовать в ежемесячном розыгрыше</button>
                        </div>
                    }
                    {game?.capitanId !== userId &&
                        <div className="actions">
                            <button className="green" onClick={() => setPage(PAGE_START)}>Сыграть еще</button>
                        </div>
                    }
                </>
            }
        </>
    );
}