export default function Gamers({ gamers, whiteBg = false, colorNumber = false, showScope = false }) {
    const isWaitingPlayers = gamers?.find(it => it?.state === 1)
    return (
        <div className={'gamersTable' + (whiteBg ? " whiteBg" : "") + (colorNumber ? " colorNumber" : "")}>
            {gamers?.map((user, key) =>
                <div className="gamer" key={key}>

                    {!isWaitingPlayers && <div className="number">{key + 1}</div>}
                    {!!isWaitingPlayers && <div className={"status" + (user.state === 1 ? " wait" : "")}></div>}
                    <div className="info">
                        <div className="name">{user.name || user.command}</div>
                        {!!isWaitingPlayers && <div className={"text" + (user.state !== 1 ? " green" : "")}>
                            {user.state === 1 ? 'Отвечает' : 'Ответил'}
                        </div>}
                        {showScope && <div className="text">
                            Правильных ответов: <span>{user.scope}</span>
                        </div>}
                    </div>
                </div>
            )}
        </div>
    )
}
