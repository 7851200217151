import useWebSocket from 'react-use-websocket';
import useLocalStorageState from 'use-local-storage-state';
import { WS_URL } from '../const';
import Timer from "../components/timer";

export default function PageGame({ question }) {
    const [userId] = useLocalStorageState('userId')
    const { sendJsonMessage } = useWebSocket(WS_URL, {
        filter: () => false,
        share: true,
        retryOnError: true,
        shouldReconnect: () => true,
        reconnectInterval: 1000,
    });


    const sendAnswer = (answer) => {
        sendJsonMessage({
            action: 'answer',
            data: {
                userId: userId,
                answer: answer
            }
        })
    }

    return (
        <>
            <Timer timeSecond={15} refresh={question?.name} />
            {!!question?.image?.length &&
                <img className="questionImg" src={'/question/' + question.image} alt="" style={{ alignSelf: "center" }} />
            }
            <div className="pageTitle">{question?.name}</div>
            <div className="actions">
                {question?.answers.map((q, k) =>
                    <button className="white" key={k} onClick={() => sendAnswer(k)}>{q}</button>
                )}
            </div>
        </>
    );
}