import { PAGE_START } from ".";

export default function PageRegistrationSuccess({ setPage }) {
    return (
        <>
            <div className="pageTitle">Спасибо за участие</div>
            <div className="pageMiddleTitle">Если вы станете лучшей командой за месяц мы оповестим вас о победе.</div>
            
            <div className="actions">
                <button className="green" onClick={() => setPage(PAGE_START)}>Назад</button>
            </div>
        </>
    );
}