import { useEffect, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import useLocalStorageState from 'use-local-storage-state';
import './App.css';
import { WS_URL } from './const';
import {
    PAGE_18,
    PAGE_START,
    PAGE_CREATE,
    PAGE_CONNECT,
    PAGE_WAIT,
    PAGE_GAME,
    PAGE_GAME_OVER,
    PAGE_REGISTRATION,
    PAGE_REGISTRATION_SUCCESS,
    PAGE_LEADERS,
    PAGE_INSTRUCTION
} from './page';
import Page18 from './page/18';
import PageInstruction from "./page/instruction";
import PageConnect from './page/connect';
import PageCreate from './page/create';
import PageGame from './page/game';
import PageGameOver from './page/gameOver';
import PageLeaders from './page/leaders';
import PageRegistration from './page/registration';
import PageRegistrationSuccess from './page/registrationSuccess';
import PageStart from './page/start';
import PageWait from './page/wait';

function App() {
    const [userId, setUserId] = useLocalStorageState('userId')
    const [page, setPage] = useState(userId ? PAGE_START : PAGE_18)
    const [, setGameId] = useLocalStorageState('gameId')
    const [lastJsonMessageSaved, setLastJsonMessageSaved] = useLocalStorageState('lastServerMessage')
    const [networkError, setNetworkError] = useState("")

    const { lastJsonMessage, sendJsonMessage, readyState } = useWebSocket(WS_URL, {
        onOpen: () => console.log('WebSocket connection established.'),
        //filter: isGlobalAction,
        share: true,
        retryOnError: true,
        shouldReconnect: () => true,
        reconnectInterval: 1000,

    });

    useEffect(() => {//reconnect 
        if (!lastJsonMessage && lastJsonMessageSaved) {
            sendJsonMessage({
                action: 'reconnect',
                data: {
                    userId: userId,
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const lastJsonMessageActual = lastJsonMessage || lastJsonMessageSaved

    useEffect(() => {
        setNetworkError("")
        if (readyState !== ReadyState.OPEN) {
            setNetworkError("Нет соединения с сервером")
        } else if (lastJsonMessage?.error?.length) {
            setNetworkError(lastJsonMessage.error)
        } else {
            if (lastJsonMessage?.action) {
                setLastJsonMessageSaved(lastJsonMessage)
            }
            if (lastJsonMessageActual?.action) {
                switch (lastJsonMessageActual.action) {
                    case "intro"://после reconnect сервер сказал что игры нет показываем стартовый экран
                        setPage(PAGE_START)
                        break;
                    case "gameCreate"://капитан создал игру
                        setUserId(lastJsonMessageActual.data.user.id)
                        setGameId(lastJsonMessageActual.data.gameId)
                        setPage(PAGE_CREATE)
                        break;
                    case "connected"://присоединен к игре, ожидает старта
                        setUserId(lastJsonMessageActual.data.user.id)
                        setGameId(lastJsonMessageActual.data.gameId)
                        setPage(PAGE_WAIT)
                        const inviteGame = window.location.search?.split('=')[1]
                        if(inviteGame) window.history.replaceState({}, document.title, "/")
                        break;
                    case "showQuestion":
                        setPage(PAGE_GAME)
                        break;
                    case "showResult":
                        setPage(PAGE_GAME_OVER)
                        break;
                    case "registrationSuccess":
                        setPage(PAGE_REGISTRATION_SUCCESS)
                        break;
                    case "showLeaders":
                        setPage(PAGE_LEADERS)
                        break;
                    default:
                }
            }
        }
    }, [readyState, lastJsonMessage, lastJsonMessageActual, setGameId, setLastJsonMessageSaved, setUserId]);


    const screenContent = () => {
        switch (page) {
            case PAGE_18: return <Page18 setPage={setPage} />
            case PAGE_INSTRUCTION: return <PageInstruction setPage={setPage} />
            case PAGE_START: return <PageStart setPage={setPage} networkError={networkError} />
            case PAGE_CREATE: return <PageCreate setPage={setPage} gamers={lastJsonMessageActual?.data?.gamers} networkError={networkError} />
            case PAGE_CONNECT: return <PageConnect setPage={setPage} networkError={networkError} />
            case PAGE_WAIT: return <PageWait setPage={setPage} />
            case PAGE_GAME: return <PageGame question={lastJsonMessageActual?.data?.question} />
            case PAGE_GAME_OVER: return <PageGameOver setPage={setPage} game={lastJsonMessageActual?.data?.game} gamers={lastJsonMessageActual?.data?.gamers} />
            case PAGE_REGISTRATION: return <PageRegistration setPage={setPage} networkError={networkError} />
            case PAGE_REGISTRATION_SUCCESS: return <PageRegistrationSuccess setPage={setPage} />
            case PAGE_LEADERS: return <PageLeaders setPage={setPage} commands={lastJsonMessageActual?.data?.commands}/>
            default: return <h1>No screen match</h1>
        }
    }

    return (
        <>
            <div className="wrapper">
                <div className="container">
                    {screenContent()}
                </div>
            </div>
        </>
    );
}

export default App;
