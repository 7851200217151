import { useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import useLocalStorageState from 'use-local-storage-state';
import { PAGE_GAME_OVER } from ".";
import { WS_URL } from '../const';
import { validateCommandName, validateEmail } from '../utils/validate';
import prize from '../images/prize.webp';

export default function PageRegistration({ setPage, networkError }) {
    const [userId] = useLocalStorageState('userId')
    const [name] = useLocalStorageState('userName')
    const [command, setCommand] = useState('')
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const { sendJsonMessage } = useWebSocket(WS_URL, {
        filter: () => false,
        share: true,
        retryOnError: true,
        shouldReconnect: () => true,
        reconnectInterval: 1000,
    });

    const registration = () => {
        if (!command.length) {
            setError("Введите название команды")
        } else if (!email.length) {
            setError("Введите ваш Email")
        } else if (validateCommandName(command) && validateEmail(email)) {
            sendJsonMessage({
                action: 'registration',
                data: {
                    userId: userId,
                    name: name,
                    command: command,
                    email: email
                }
            })
        }
    }

    useEffect(() => {
        setError("")
        if (!validateCommandName(command)) {
            setError("Имя может содержать только русские и английские буквы, дефис")
        } else if (!validateEmail(email)) {
            setError("Некорректный формат Email")
        }
    }, [command, email]);

    return (
        <>
            <div className="pageTitle">Ежемесячный розыгрыш</div>
            <div className="pageMiddleTitle">Лучшая команда за месяц выигрывает призы</div>
            <img className="prize" src={prize} width="274" height="173" alt="" />
            <div className="pageSubTitle">
                Баллы вашей команды суммируются и вносятся в рейтинговую таблицу.<br />
                Для участия введите название команды и вашу почту для связи.
            </div>
            <label>Название команды</label>
            <input type="text" value={command} onChange={({ target: { value } }) => setCommand(value)} />
            <label>Email</label>
            <input type="email" value={email} onChange={({ target: { value } }) => setEmail(value)} />
            {error && <p className="error">{error}</p>}
            {networkError && <p className="error">{networkError}</p>}
            <div className="actions">
                <button className="green" onClick={registration}>Участвовать</button>
                <button className="red border" onClick={() => setPage(PAGE_GAME_OVER)}>Назад</button>
                <div className="pageSubTitle">
                    Нажимая на кнопку “Участвовать”, вы соглашаетесь с <a href="/policy.pdf" target="_blank">Политикой обработки персональных данных</a>
                </div>
            </div>
        </>
    );
}