import { PAGE_START } from ".";
import logo from '../images/logo.svg';

export default function PageWait({ setPage }) {
    return (
        <>
            <video width="500" height="100%" autoPlay loop muted playsInline className="loader">
                <source src="/video/loader2.mp4" type='video/mp4;' />
            </video>
            <div className="loader_text">
                <img className="logo" src={logo} width={200} height={60} alt="" />
                <div className="actions" style={{ alignItems: "center" }}>
                    <div className="pageTitleWaitForStart">
                        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.46886 1.93678C10.5582 1.93678 11.6138 2.14983 12.6069 2.57001C13.5667 2.97614 14.4293 3.55759 15.1705 4.29845C15.9114 5.03932 16.4932 5.90187 16.899 6.86207C17.3191 7.8552 17.5322 8.91119 17.5322 10.0001C17.5322 11.089 17.3191 12.145 16.899 13.1382C16.4928 14.098 15.9114 14.9605 15.1705 15.7018C14.4297 16.4426 13.5671 17.0245 12.6069 17.4302C11.6138 17.8504 10.5578 18.0634 9.46886 18.0634C8.37994 18.0634 7.32395 17.8504 6.33082 17.4302C5.37099 17.0241 4.50844 16.4426 3.7672 15.7018C3.02634 14.9609 2.44452 14.0984 2.03876 13.1382C1.61858 12.145 1.40553 11.089 1.40553 10.0001C1.40553 8.91119 1.61858 7.8552 2.03876 6.86207C2.44489 5.90224 3.02634 5.03969 3.7672 4.29845C4.50807 3.55759 5.37062 2.97577 6.33082 2.57001C7.32395 2.14983 8.37994 1.93678 9.46886 1.93678ZM9.46886 0.53125C4.23954 0.53125 0 4.77079 0 10.0001C0 15.2294 4.23954 19.469 9.46886 19.469C14.6982 19.469 18.9377 15.2298 18.9377 10.0001C18.9377 4.77042 14.6982 0.53125 9.46886 0.53125Z" fill="#921634" />
                            <path d="M13.4602 10.708H9.46888C9.08088 10.708 8.76611 10.3933 8.76611 10.0053V4.48597C8.76611 4.09797 9.08088 3.7832 9.46888 3.7832C9.85688 3.7832 10.1716 4.09797 10.1716 4.48597V9.30252H13.4602C13.8482 9.30252 14.163 9.61728 14.163 10.0053C14.163 10.3933 13.8482 10.708 13.4602 10.708Z" fill="#921634" />
                        </svg>
                        Ожидаем остальных игроков
                    </div>
                    <div className="pageSubTitle">
                        Ваша задача ответить правильно на максимальное количество вопросов за 10 секунд.<br />Побеждайте и становитесь участником розыгрыша яблочных призов.
                    </div>

                </div>
                <div className="actions" style={{ marginTop: 20 }}>
                    <button className="red border" onClick={() => setPage(PAGE_START)}>Назад</button>
                </div>
            </div>
        </>
    );
}