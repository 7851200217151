export const inviteFriends = async (gameId) => {
    const shareData = {
        title: "Время поднять градус вечера и сыграть в самый пикантный квиз.",
        text: "Проверьте вашу эрудицию и смекалку. Открывайте сидр и начинайте ваш баттл.",
        url: "https://game.ya-cider.ru/?game=" + gameId,
    };
    try {
        await navigator.share(shareData);
    } catch (err) {
        alert("Не удалось поделиться. Но ваши друзья могут нажать кнопку «Присоединится» на стартовом экране и ввести номер игры.")
    }
}