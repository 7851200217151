import { useEffect, useState } from "react";

export default function Timer({timeSecond, refresh }) {
    const [start, setStart] = useState(false)
    const [timer, setTimer] = useState(timeSecond)

    useEffect(() => {
        setTimer(timeSecond)
        setStart(false)
        setTimeout(() => setStart(true), 10)
        const interval = setInterval(() => {
            setTimer(timer => timer > 0 ? timer - 1 : timeSecond)
        }, 1000);
        return () => clearInterval(interval);
    }, [timeSecond, refresh]);

    return (
        <div className="timer">
            <div className="line"></div>
            <div className="circle">
                <div className={"progress" + (start ? " start" : "")}style={{ "--timer-total": (timeSecond+1)+"s" }}/>
                <div className="value">{timer}</div>
            </div>
        </div>
    )
}