import useWebSocket from 'react-use-websocket';
import useLocalStorageState from "use-local-storage-state";
import { WS_URL } from "../const";
import { inviteFriends } from "../utils/inviteFriends";
import Gamers from '../components/gamers';
import { PAGE_START } from ".";

export default function PageCreate({ setPage, gamers, networkError }) {
    const [gameId] = useLocalStorageState('gameId')
    const [userId] = useLocalStorageState('userId')
    const { sendJsonMessage } = useWebSocket(WS_URL, {
        filter: () => false,
        share: true,
        retryOnError: true,
        shouldReconnect: () => true,
        reconnectInterval: 1000,
    });

    const startGame = () => {
        sendJsonMessage({
            action: 'start',
            data: {
                userId: userId,
            }
        })
    }

    return (
        <>
            <div className="pageTitle">Новая игра</div>
            <div className="pageSubTitle">
                Отлично! Вы создатель новой игры.<br />Сообщите участником номер игры и дождитесь их подключения. Или нажмите кнопку “Пригласить друзей” и поделитесь ссылкой на игру в мессенджерах.<br />Дождитесь всех участников и жмите “Старт“.
            </div>
            <div className="gameNumber">Номер игры: <span>{gameId}</span></div>
            {networkError && <p className="error">{networkError}</p>}
            <div className="middleBox">
                <Gamers gamers={gamers} whiteBg={true} />
            </div>
            <div className="actions">
                <button className="green" onClick={startGame}>Старт</button>
                <button className="red border" onClick={() => inviteFriends(gameId)}>Пригласить друзей</button>
                <button className="orange" onClick={() => setPage(PAGE_START)}>Назад</button>
            </div>
        </>
    );
}