import { PAGE_INSTRUCTION } from ".";
import img18 from '../images/18.svg';

export default function Page18({ setPage }) {
    return (
        <>
            <div className="middleBox">
                <div className="pageTitle red">
                    Яблочко созрело?<br />
                    Наша игра и наш сидр предназначены только для лиц старше 18 лет.<br/>
                    Для входа в квиз, подтвердите свой возраст.<br /><br />
                    Вам есть 18 лет?
                </div>
                <img src={img18} width="160" height="160" alt="" style={{ alignSelf: "center", marginBottom: 35 }} />
            </div>
            <div className="actions row">
                <button className="green" onClick={() => setPage(PAGE_INSTRUCTION)}>Да</button>
                <button className="orange" onClick={() => alert("Пригласите поиграть родителей =)")}>Нет</button>
            </div>
        </>
    );
}