import { useState } from "react";
import useWebSocket from 'react-use-websocket';
import useLocalStorageState from "use-local-storage-state";
import { WS_URL } from "../const";
import { PAGE_START } from ".";

export default function PageConnect({ setPage, networkError }) {
    const [name] = useLocalStorageState('userName', { defaultValue: "" });
    const [gameNumber, setGameNumber] = useState('');
    const { sendJsonMessage } = useWebSocket(WS_URL, {
        share: true,
        filter: () => false,
        retryOnError: true,
        shouldReconnect: () => true,
        reconnectInterval: 1000,
    });

    const connectGame = () => {
        const gameId = parseInt(gameNumber)
        if (isNaN(gameId) || gameId < 1) return
        sendJsonMessage({
            action: 'connect',
            data: {
                gameId: gameId,
                name: name
            }
        })
    }

    return (
        <>
            <div className="pageTitle">Вам бросили вызов в самом пикантном квизе! </div>
            <div className="pageSubTitle">Спросите создателя номер игры и введите его в поле. Номер, конечно!</div>
            <div className="middleBox">
                <label>Введите номер игры</label>
                <input type="text" onChange={({ target: { value } }) => setGameNumber(
                    !isNaN(parseInt(value.trim())) ? parseInt(value.trim()) : ""
                )} value={gameNumber} />
                {networkError && <p className="error">{networkError}</p>}
            </div>
            <div className="actions">
                <button className="green" onClick={connectGame}>Присоединится</button>
                <button className="red border" onClick={() => setPage(PAGE_START)}>Назад</button>
            </div>
        </>
    );
}